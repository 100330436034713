body {
  margin: 0;
}

.logo {
  /* height: 200px; */
  width: 300px;
}

.logoImage {
  width: 100%;
  max-width: 300px;
  height: 100%;
  max-height: 100px;
}

.previous {
  position: fixed;
  top: 60%;
  /* left: 17px; */
  /* transform: translate(-50%, -50%); */
  color: white;
  padding-top: 7px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
}

.previousText {
  position: fixed;
  top: 60%;
  left: 40px;
  transform: translate(-50%, -50%);
}



@media only screen  and (max-width: 600px) {
  .next {
    right: 0px;
  }
}


/*   600px and up */
@media only screen and (min-width: 600px) {
  .next {
    right: -17px;
  }
  .nextText {
    right: -50px;
  }
}

.next {
  position: fixed;
  top: 60%;
  /* transform: translate(-50%, -50%); */
  color: white;
  padding-top: 7px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
}

.nextText {
  position: fixed;
  top: 60%;
  transform: translate(-50%, -50%);
}




.nav_icons {
  transform: scale(1.8);
}

/* .previous button {
  border-radius: 1px;
} */

.mediaContainer {
  height: 100%;
  width: 100%;
}
.imgTile {
  width: 100%;
  /* width: 26em; */
}

.imgTileCollection {
  width: 100%;
  max-width: 20em;
}

.imgHorizontalTile {
  width: 100%;
  max-width: 15em;
  margin-bottom: 5px;
  margin-top: 10px;
}

.titleWidthImage img {
  vertical-align: middle;
  max-height: 50px;
  max-width: 160px;
  margin-bottom: 5px;
}

.summaryContainer {
  padding-bottom: 5em;
  padding-top: 5em;
}

/*  
max-height: 25px;
max-width: 160px;
*/

/* .blocksContainer {
  margin: 50px;
  padding: 50px;
  background-color: red;
} */


.darkBandImage {
  /* width: 100%; */
  height: 100%;
  /* max-height: 40vh; */
}


/* Extra small devices (phones, 600px and down)*/
@media only screen and (max-width: 600px) {
  .darkBand {
    background-image: url("https://proair.datris.eu/tecnosystemi/images/sfondo-proair-logo_mobile.png");
    height: 25vh;
    background-size: auto 100%;

    background-position: center;
  }
}

/*   600px and up */
@media only screen and (min-width: 600px) {
  .darkBand {
    background-image: url("https://proair.datris.eu/tecnosystemi/images/sfondo-proair-logo.png");
    height: 22vh;
    background-size: auto 100%;

    background-position: center;
  }
}

/* Extra small devices (phones, 600px and down)*/
@media only screen and (max-width: 600px) {
  .darkBand_it {
    background-image: url("https://proair.datris.eu/tecnosystemi/images/sfondo-proair-logo_mobile_ita.png");
    height: 24vh;
    background-size: auto 100%;

    background-position: center;
  }
}

@media only screen and (min-width: 600px) {
  .darkBand_it {
    background-image: url("https://proair.datris.eu/tecnosystemi/images/sfondo-proair-logo_ita.png");
    height: 25vh;
    background-size: auto 100%;
    background-position: center;
  }
} 
